/* eslint-disable */
export default {
      "share@NATIVE_SHARING_MW_LANDING_PAGE/ACTION": 0,
      "settings@cookies.footer.cookie_policy": 1,
      "onboarding@bumble.incognito.dismiss_warning.cta.cancel": 2,
      "signin@PHONE_INCORRECT": 3,
      "corp@copyright_notice": 4,
      "bumblesite@ccpa_cta.title": 5,
      "bumblesite@LAYOUT.FOOTER.MANAGE_COOKIES": 6,
      "bumblesite@team_bumble.ceo.full_name": 7,
      "bumblesite@team_bumble.page_about.badoo_app_block.subtitle": 8,
      "bumblesite@team_bumble.page_about.badoo_app_block.text": 9,
      "bumblesite@team_bumble.page_about.badoo_app_block.title": 10,
      "bumblesite@team_bumble.page_about.bumble_app_block.subtitle": 11,
      "bumblesite@team_bumble.page_about.bumble_app_block.text.first": 12,
      "bumblesite@team_bumble.page_about.bumble_app_block.text.second": 13,
      "bumblesite@team_bumble.page_about.bumble_app_block.title": 14,
      "bumblesite@team_bumble.page_about.hero_block.title.first": 15,
      "bumblesite@team_bumble.page_about.hero_block.title.second": 16,
      "bumblesite@team_bumble.page_about.real_connections_block.title": 17,
      "bumblesite@team_bumble.page_austin.cap_metro.text": 18,
      "bumblesite@team_bumble.page_austin.cap_metro.title": 19,
      "bumblesite@team_bumble.page_austin.celebrations.text": 20,
      "bumblesite@team_bumble.page_austin.celebrations.title": 21,
      "bumblesite@team_bumble.page_austin.community_block.title": 22,
      "bumblesite@team_bumble.page_austin.dental.text": 23,
      "bumblesite@team_bumble.page_austin.dental.title": 24,
      "bumblesite@team_bumble.page_austin.disability_insurance.text": 25,
      "bumblesite@team_bumble.page_austin.disability_insurance.title": 26,
      "bumblesite@team_bumble.page_austin.eap.text": 27,
      "bumblesite@team_bumble.page_austin.eap.title": 28,
      "bumblesite@team_bumble.page_austin.eip.text": 29,
      "bumblesite@team_bumble.page_austin.eip.title": 30,
      "bumblesite@team_bumble.page_austin.family_care.text": 31,
      "bumblesite@team_bumble.page_austin.family_care.title": 32,
      "bumblesite@team_bumble.page_austin.family_planning.text": 33,
      "bumblesite@team_bumble.page_austin.family_planning.title": 34,
      "bumblesite@team_bumble.page_austin.financial.title": 35,
      "bumblesite@team_bumble.page_austin.food.text": 36,
      "bumblesite@team_bumble.page_austin.food.title": 37,
      "bumblesite@team_bumble.page_austin.hackathon.text": 38,
      "bumblesite@team_bumble.page_austin.hackathon.title": 39,
      "bumblesite@team_bumble.page_austin.happiness_block.title": 40,
      "bumblesite@team_bumble.page_austin.health_savings.text": 41,
      "bumblesite@team_bumble.page_austin.health_savings.title": 42,
      "bumblesite@team_bumble.page_austin.health_wellbeing_block.title": 43,
      "bumblesite@team_bumble.page_austin.hospital.text": 44,
      "bumblesite@team_bumble.page_austin.hospital.title": 45,
      "bumblesite@team_bumble.page_austin.insurance.text": 46,
      "bumblesite@team_bumble.page_austin.insurance.title": 47,
      "bumblesite@team_bumble.page_austin.internal_talks.text": 48,
      "bumblesite@team_bumble.page_austin.internal_talks.title": 49,
      "bumblesite@team_bumble.page_austin.learning_block.title": 50,
      "bumblesite@team_bumble.page_austin.learning_budget.text": 51,
      "bumblesite@team_bumble.page_austin.learning_budget.title": 52,
      "bumblesite@team_bumble.page_austin.life_insurance.text": 53,
      "bumblesite@team_bumble.page_austin.life_insurance.title": 54,
      "bumblesite@team_bumble.page_austin.mental_health.text": 55,
      "bumblesite@team_bumble.page_austin.mental_health.title": 56,
      "bumblesite@team_bumble.page_austin.merch.text": 57,
      "bumblesite@team_bumble.page_austin.merch.title": 58,
      "bumblesite@team_bumble.page_austin.page_title": 59,
      "bumblesite@team_bumble.page_austin.paid_time.text": 60,
      "bumblesite@team_bumble.page_austin.paid_time.title": 61,
      "bumblesite@team_bumble.page_austin.parental_leave.text": 62,
      "bumblesite@team_bumble.page_austin.parental_leave.title": 63,
      "bumblesite@team_bumble.page_austin.premium_app.text": 64,
      "bumblesite@team_bumble.page_austin.premium_app.title": 65,
      "bumblesite@team_bumble.page_austin.private_medical_insurance.text": 66,
      "bumblesite@team_bumble.page_austin.private_medical_insurance.title": 67,
      "bumblesite@team_bumble.page_austin.random_coffee.text": 68,
      "bumblesite@team_bumble.page_austin.random_coffee.title": 69,
      "bumblesite@team_bumble.page_austin.retirement_plan.text": 70,
      "bumblesite@team_bumble.page_austin.retirement_plan.title": 71,
      "bumblesite@team_bumble.page_austin.team_activities.text": 72,
      "bumblesite@team_bumble.page_austin.team_activities.title": 73,
      "bumblesite@team_bumble.page_austin.wellness_budget.text": 74,
      "bumblesite@team_bumble.page_austin.wellness_budget.title": 75,
      "bumblesite@team_bumble.page_barcelona.page.text": 76,
      "bumblesite@team_bumble.page_barcelona.page.title": 77,
      "bumblesite@team_bumble.page_benefits.card.austin.subtitle": 78,
      "bumblesite@team_bumble.page_benefits.card.austin.title": 79,
      "bumblesite@team_bumble.page_benefits.card.barcelona.coming_soon": 80,
      "bumblesite@team_bumble.page_benefits.card.barcelona.subtitle": 81,
      "bumblesite@team_bumble.page_benefits.card.barcelona.title": 82,
      "bumblesite@team_bumble.page_benefits.card.london.subtitle": 83,
      "bumblesite@team_bumble.page_benefits.card.london.title": 84,
      "bumblesite@team_bumble.page_benefits.card.moscow.subtitle": 85,
      "bumblesite@team_bumble.page_benefits.card.moscow.title": 86,
      "bumblesite@team_bumble.page_benefits.page.intro": 87,
      "bumblesite@team_bumble.page_benefits.page.title": 88,
      "bumblesite@team_bumble.page_careers.communications_block.text": 89,
      "bumblesite@team_bumble.page_careers.communications_block.title": 90,
      "bumblesite@team_bumble.page_careers.community_block.text": 91,
      "bumblesite@team_bumble.page_careers.community_block.title": 92,
      "bumblesite@team_bumble.page_careers.culture_block.text": 93,
      "bumblesite@team_bumble.page_careers.culture_block.title": 94,
      "bumblesite@team_bumble.page_careers.design_block.text": 95,
      "bumblesite@team_bumble.page_careers.design_block.title": 96,
      "bumblesite@team_bumble.page_careers.engineering_block.text": 97,
      "bumblesite@team_bumble.page_careers.engineering_block.title": 98,
      "bumblesite@team_bumble.page_careers.engineering_block.title.uppercase": 99,
      "bumblesite@team_bumble.page_careers.excellence_block.text": 100,
      "bumblesite@team_bumble.page_careers.excellence_block.title": 101,
      "bumblesite@team_bumble.page_careers.finance_block.text": 102,
      "bumblesite@team_bumble.page_careers.finance_block.title": 103,
      "bumblesite@team_bumble.page_careers.intelligence_block.text": 104,
      "bumblesite@team_bumble.page_careers.intelligence_block.title": 105,
      "bumblesite@team_bumble.page_careers.learn_more_cta": 106,
      "bumblesite@team_bumble.page_careers.legal_block.text": 107,
      "bumblesite@team_bumble.page_careers.legal_block.title": 108,
      "bumblesite@team_bumble.page_careers.marketing_block.text": 109,
      "bumblesite@team_bumble.page_careers.marketing_block.title": 110,
      "bumblesite@team_bumble.page_careers.marketing_block.title.uppercase": 111,
      "bumblesite@team_bumble.page_careers.page.header": 112,
      "bumblesite@team_bumble.page_careers.page.title": 113,
      "bumblesite@team_bumble.page_careers.product_block.text": 114,
      "bumblesite@team_bumble.page_careers.product_block.title": 115,
      "bumblesite@team_bumble.page_careers.reseach_block.text": 116,
      "bumblesite@team_bumble.page_careers.reseach_block.title": 117,
      "bumblesite@team_bumble.page_design.badoo_block.text": 118,
      "bumblesite@team_bumble.page_design.badoo_block.title": 119,
      "bumblesite@team_bumble.page_design.bumble_block.text": 120,
      "bumblesite@team_bumble.page_design.bumble_block.title": 121,
      "bumblesite@team_bumble.page_design.design_block.text": 122,
      "bumblesite@team_bumble.page_design.design_block.title": 123,
      "bumblesite@team_bumble.page_design.design_block.title.uppercase": 124,
      "bumblesite@team_bumble.page_engineering.good_block.text": 125,
      "bumblesite@team_bumble.page_engineering.good_block.title": 126,
      "bumblesite@team_bumble.page_engineering.page.header": 127,
      "bumblesite@team_bumble.page_engineering.page.intro": 128,
      "bumblesite@team_bumble.page_engineering.technology_block.text": 129,
      "bumblesite@team_bumble.page_engineering.technology_block.title": 130,
      "bumblesite@team_bumble.page_engineering.video_block.cta": 131,
      "bumblesite@team_bumble.page_engineering.video_block.text.first": 132,
      "bumblesite@team_bumble.page_engineering.video_block.text.second": 133,
      "bumblesite@team_bumble.page_engineering.video_block.title": 134,
      "bumblesite@team_bumble.page_index.ceo_message.text": 135,
      "bumblesite@team_bumble.page_index.ceo_message.title": 136,
      "bumblesite@team_bumble.page_index.cookie.banner.accept_all": 137,
      "bumblesite@team_bumble.page_index.cookie.banner.manage_options": 138,
      "bumblesite@team_bumble.page_index.cookie.banner.text": 139,
      "bumblesite@team_bumble.page_index.cookie.banner.title": 140,
      "bumblesite@team_bumble.page_index.footer.body": 141,
      "bumblesite@team_bumble.page_index.footer.links.cookie": 142,
      "bumblesite@team_bumble.page_index.footer.links.gender_gap": 143,
      "bumblesite@team_bumble.page_index.footer.links.privacy": 144,
      "bumblesite@team_bumble.page_index.footer.links.slavery": 145,
      "bumblesite@team_bumble.page_index.footer.links.terms": 146,
      "bumblesite@team_bumble.page_index.hero_block.title": 147,
      "bumblesite@team_bumble.page_index.navigation.links.about": 148,
      "bumblesite@team_bumble.page_index.navigation.links.benefits": 149,
      "bumblesite@team_bumble.page_index.navigation.links.life": 150,
      "bumblesite@team_bumble.page_index.navigation.links.teams": 151,
      "bumblesite@team_bumble.page_index.navigation.menu": 152,
      "bumblesite@team_bumble.page_index.navigation.menu.join_cta": 153,
      "bumblesite@team_bumble.page_index.navigation.open_roles": 154,
      "bumblesite@team_bumble.page_index.success_story.couple.names": 155,
      "bumblesite@team_bumble.page_index.success_story.text": 156,
      "bumblesite@team_bumble.page_index.success_story.title": 157,
      "bumblesite@team_bumble.page_life.life_block.header": 158,
      "bumblesite@team_bumble.page_life.life_block.text.first": 159,
      "bumblesite@team_bumble.page_life.life_block.text.second": 160,
      "bumblesite@team_bumble.page_life.life_block.title": 161,
      "bumblesite@team_bumble.page_life.moves_block.career.text": 162,
      "bumblesite@team_bumble.page_life.moves_block.career.title": 163,
      "bumblesite@team_bumble.page_life.moves_block.change.text": 164,
      "bumblesite@team_bumble.page_life.moves_block.change.title": 165,
      "bumblesite@team_bumble.page_life.moves_block.good.text": 166,
      "bumblesite@team_bumble.page_life.moves_block.good.title": 167,
      "bumblesite@team_bumble.page_life.moves_block.header": 168,
      "bumblesite@team_bumble.page_life.moves_block.title": 169,
      "bumblesite@team_bumble.page_life.testimonials_badoo_block.header": 170,
      "bumblesite@team_bumble.page_life.testimonials_badoo_block.title": 171,
      "bumblesite@team_bumble.page_life.testimonials_badoo_video_block.title": 172,
      "bumblesite@team_bumble.page_life.testimonials_block.employee_first.caption": 173,
      "bumblesite@team_bumble.page_life.testimonials_block.employee_first.location": 174,
      "bumblesite@team_bumble.page_life.testimonials_block.employee_second.caption": 175,
      "bumblesite@team_bumble.page_life.testimonials_block.employee_second.location": 176,
      "bumblesite@team_bumble.page_life.testimonials_block.employee_third.caption": 177,
      "bumblesite@team_bumble.page_life.testimonials_block.employee_third.location": 178,
      "bumblesite@team_bumble.page_life.testimonials_block.header": 179,
      "bumblesite@team_bumble.page_life.testimonials_block.title": 180,
      "bumblesite@team_bumble.page_life.values_block.accountability.text": 181,
      "bumblesite@team_bumble.page_life.values_block.accountability.title": 182,
      "bumblesite@team_bumble.page_life.values_block.first_move.text": 183,
      "bumblesite@team_bumble.page_life.values_block.first_move.title": 184,
      "bumblesite@team_bumble.page_life.values_block.growth.text": 185,
      "bumblesite@team_bumble.page_life.values_block.growth.title": 186,
      "bumblesite@team_bumble.page_life.values_block.header": 187,
      "bumblesite@team_bumble.page_life.values_block.honesty.text": 188,
      "bumblesite@team_bumble.page_life.values_block.honesty.title": 189,
      "bumblesite@team_bumble.page_life.values_block.inclusivity.text": 190,
      "bumblesite@team_bumble.page_life.values_block.inclusivity.title": 191,
      "bumblesite@team_bumble.page_life.values_block.kindness.text": 192,
      "bumblesite@team_bumble.page_life.values_block.kindness.title": 193,
      "bumblesite@team_bumble.page_london.annual_leave_block.text": 194,
      "bumblesite@team_bumble.page_london.annual_leave_block.title": 195,
      "bumblesite@team_bumble.page_london.babylon_app_block.text": 196,
      "bumblesite@team_bumble.page_london.babylon_app_block.title": 197,
      "bumblesite@team_bumble.page_london.conference_block.text.new": 198,
      "bumblesite@team_bumble.page_london.conference_block.title": 199,
      "bumblesite@team_bumble.page_london.cycle_block.text": 200,
      "bumblesite@team_bumble.page_london.cycle_block.title": 201,
      "bumblesite@team_bumble.page_london.dental_insurance.text": 202,
      "bumblesite@team_bumble.page_london.dental_insurance.title": 203,
      "bumblesite@team_bumble.page_london.double_pension_block.text": 204,
      "bumblesite@team_bumble.page_london.double_pension_block.title": 205,
      "bumblesite@team_bumble.page_london.eip.text": 206,
      "bumblesite@team_bumble.page_london.eip.title": 207,
      "bumblesite@team_bumble.page_london.food.text": 208,
      "bumblesite@team_bumble.page_london.gym_flex_block.text": 209,
      "bumblesite@team_bumble.page_london.gym_flex_block.title": 210,
      "bumblesite@team_bumble.page_london.income_protection_block.text": 211,
      "bumblesite@team_bumble.page_london.income_protection_block.title": 212,
      "bumblesite@team_bumble.page_london.mental_health_block.text": 213,
      "bumblesite@team_bumble.page_london.mental_health_block.title": 214,
      "bumblesite@team_bumble.page_london.page.title": 215,
      "bumblesite@team_bumble.page_london.parental_leave_block.text": 216,
      "bumblesite@team_bumble.page_london.parental_leave_block.title": 217,
      "bumblesite@team_bumble.page_london.payroll_block.text": 218,
      "bumblesite@team_bumble.page_london.payroll_block.title": 219,
      "bumblesite@team_bumble.page_london.private_medical_insurance.title": 220,
      "bumblesite@team_bumble.page_london.season_ticket_block.text": 221,
      "bumblesite@team_bumble.page_london.season_ticket_block.title": 222,
      "bumblesite@team_bumble.page_london.sports_block.text": 223,
      "bumblesite@team_bumble.page_london.sports_block.title": 224,
      "bumblesite@team_bumble.page_london.team_activities_block.text": 225,
      "bumblesite@team_bumble.page_london.team_activities_block.title": 226,
      "bumblesite@team_bumble.page_london.vision_vouchers_block.text": 227,
      "bumblesite@team_bumble.page_london.vision_vouchers_block.title": 228,
      "bumblesite@team_bumble.page_london.wellbeing_room_block.text": 229,
      "bumblesite@team_bumble.page_london.wellbeing_room_block.title": 230,
      "bumblesite@team_bumble.page_marketing.badoo_block.text": 231,
      "bumblesite@team_bumble.page_marketing.badoo_block.title": 232,
      "bumblesite@team_bumble.page_marketing.bumble_block.text.first": 233,
      "bumblesite@team_bumble.page_marketing.bumble_block.text.second": 234,
      "bumblesite@team_bumble.page_marketing.bumble_block.title": 235,
      "bumblesite@team_bumble.page_moscow.accident_insurance.text": 236,
      "bumblesite@team_bumble.page_moscow.accident_insurance.title": 237,
      "bumblesite@team_bumble.page_moscow.annual_leave.text": 238,
      "bumblesite@team_bumble.page_moscow.annual_leave.title": 239,
      "bumblesite@team_bumble.page_moscow.flexible_benefits.text.new": 240,
      "bumblesite@team_bumble.page_moscow.flexible_benefits.title": 241,
      "bumblesite@team_bumble.page_moscow.health_insurance.text": 242,
      "bumblesite@team_bumble.page_moscow.health_insurance.title": 243,
      "bumblesite@team_bumble.page_moscow.office_doctor.text": 244,
      "bumblesite@team_bumble.page_moscow.office_doctor.title": 245,
      "bumblesite@team_bumble.page_moscow.page.title": 246,
      "bumblesite@team_bumble.page_moscow.pharmacy_progam.text": 247,
      "bumblesite@team_bumble.page_moscow.pharmacy_progam.title": 248,
      "bumblesite@team_bumble.page_moscow.table_tennis.text": 249,
      "bumblesite@team_bumble.page_moscow.table_tennis.title": 250,
      "bumblesite@team_bumble.page_open_roles.apply_now.cta": 251,
      "bumblesite@team_bumble.page_open_roles.locations.dropdown.title": 252,
      "bumblesite@team_bumble.page_open_roles.page.title": 253,
      "bumblesite@team_bumble.page_open_roles.share_job.title": 254,
      "bumblesite@team_bumble.page_open_roles.sort_by.dropdown.title": 255,
      "bumblesite@team_bumble.page_open_roles.teams.dropdown.title": 256,
      "bumblesite@team_bumble.sharing_assets.bumble_homepage_tab.title": 257,
      "bumblesite@team_bumble.sharing_assets.bumble_life_tab.title": 258,
      "bumblesite@team_bumble.sharing_assets.hiring_block.text": 259,
      "bumblesite@team_bumble.sharing_assets.hiring_block.title": 260,
      "bma_client@cookie-notification.text": 261,
      "bma_client@dialog.photo_verification.not_access.download_app": 262,
      "bma_client@download_app_android": 263,
      "bma_client@download_app_ios": 264,
      "bma_client@pages.error.not-found.description": 265,
      "bma_client@pages.login-password.submit": 266,
      "bma_client@pages.registration.form.submit": 267,
      "bma_client@pages.registration.form.title": 268,
      "bma_client@video_chat.error_title": 269
} as const;
