/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Get Bumble",
      "Cookie Policy",
      "Go back",
      "Incorrect phone number",
      "© {{current_year}} Bumble | All Rights Reserved",
      "Do not sell or share my personal information",
      "Manage Cookies",
      "<span class=\"is-branded\">Whitney Wolfe Herd</span>",
      "<span class=\"is-branded\">Date</span> Honestly.",
      "As a community with millions of members across 190 countries, we represent people of all backgrounds and pasts. We believe everyone deserves connection and we exist to democratise dating for all. {{page_badoo_name}} empowers all people to build connections by authentically expressing who they really are and what they really want.",
      "ABOUT THE BADOO APP",
      "<span class=\"is-branded\">Make the</span> First Move.",
      "Bumble is a connections app built by women, for everyone. Founded in 2014 by CEO Whitney Wolfe Herd, the goal was to challenge and change the rules of dating.",
      "Today, people use Bumble to connect with confidence in love, life, and work.",
      "ABOUT THE BUMBLE APP",
      "<span class=\"has-circle\">2 APPS,</span>",
      "ONE <span class=\"is-italic\"><span class=\"has-underline\">MISSION</span></span>",
      "<span class=\"is-italic\"><span class=\"has-underline\">REAL</span></span> CONNECTIONS",
      "When you’re working from the office, we’ll cover the cost of travelling when you use public transport.",
      "CapMetro pass",
      "We’ll celebrate your milestones and show appreciation with a small gift.",
      "Birthday, Work Anniversary, and Baby Celebrations",
      "Community",
      "Dental and Vision coverage is also provided for you and your family.",
      "Dental and Vision Insurance",
      "We sponsor disability benefits should you become permanently disabled during your employment.",
      "Long-Term Disability Insurance",
      "Resources are available to all employees for confidential support and assistance with anything life throws at you.",
      "Employee Assistance Program (EAP)",
      "You can opt into our Employee Incentive Plan to share in the success of our company.",
      "Employee Incentive Plan (EIP)",
      "We support working parents by providing access to a private nursing suite when working in the office. When you’re remote or travelling, we’ll help with childcare accommodations and cover costs to ship breast milk.",
      "Family Care",
      "We support future parents by providing discounts for IVF, IUI, and egg freezing.",
      "Family Planning",
      "Financial",
      "When working in the office, enjoy free lunch Monday through Thursday, a fully stocked kitchen, and cold brew and kombucha on tap. While working from home, we’ll send you meal delivery or a food voucher every first Friday of the month.",
      "Food",
      "We host global hackathons for all Bumble and {{page_badoo_name}} employees. This is your chance to learn new skills, collaborate with other departments, and make moves for the business.",
      "Bumble Hackathon",
      "Happiness",
      "Set aside pre-tax dollars for use on out of pocket medical, dental, vision, and prescription expenses.",
      "Flexible Health Savings and Spending Accounts",
      "Health & Wellbeing",
      "You can also opt in to receive extra financial resources if you’re admitted into a hospital.",
      "Hospital Insurance",
      "You can opt in for additional health coverage should you ever be involved in an accident (inside or outside of work).",
      "Accident Insurance",
      "You’ll be invited to regular internal talks with in-house experts, members of our Executive Leadership Team, and special guests.",
      "Internal Talks & AMAs",
      "Learning & Development",
      "You’ll get a learning allowance every year to help with your personal development and career goals.",
      "Learning & Development Budget",
      "Bumble covers 100% of the premium for life insurance 1.5x your annual salary (up to $150,000).",
      "Life Insurance",
      "We all need a break sometimes. When that happens, your manager will work with you to give you time for self-care.",
      "Mental Health Days",
      "You’ll receive credit to spend in our exclusive Bumble merch store year round.",
      "Quarterly Bumble Merch Allowance",
      "AUSTIN <span class=\"is-italic\"><span class=\"has-circle\">BENEFITS</span></span>",
      "No matter what your seniority level is, you’ll be guaranteed 15 days of paid time off annually– and you can carry over up to 5 days if you don’t use it all.",
      "15 PTO Days",
      "Take the time you need to bond with your new child.",
      "16 Weeks Paid Parental Leave",
      "Whether it’s for work or personal use, every employee will get free Bumble Premium or {{page_badoo_name}} Premium for the duration of their employment.",
      "Premium App Access",
      "With a variety of United Healthcare plans to choose from, you can pick the health coverage that best meets you and your family’s needs. We’ll cover the cost.",
      "Private Medical Insurance",
      "Get matched with a random colleague every 2 weeks for a virtual coffee chat to spread camaraderie.",
      "Random Coffee",
      "We’ll match your contributions up to 4% of your annual salary.",
      "401k Retirement Plan",
      "You’ll get to participate in fun, virtual team-building activities within your department",
      "Regular Team Activities",
      "Whether it’s for virtual workouts or a fancy face mask, we encourage you to invest in your personal wellness to regroup and recharge.",
      "$100 Monthly Wellness Budget",
      "We’re in the process of setting up our newest office space and putting together a very exciting benefits package. Check back here or contact us to learn more.",
      "BARCELONA <span class=\"is-italic\"><span class=\"has-circle\">BENEFITS</span></span>",
      "USA",
      "Austin",
      "Coming soon",
      "SPAIN",
      "Barcelona",
      "UK",
      "London",
      "RUSSIA",
      "Moscow",
      "The best way to create a more equitable and healthy world is by starting within. We offer a highly competitive benefits package to keep you happy and healthy.",
      "OUR <span class=\"is-italic\"><span class=\"has-underline\">BENEFITS</span></span>",
      "Our purpose is to communicate the Bumble story in a way that inspires people to connect and form a deeper bond with our brand. We work cross-departmentally to gather and strategically share our stories with both internal and external audiences. We focus on the best ways to showcase our brand, our product, and our initiatives. We are most successful when we form solid, authentic relationships with internal departments and external press contacts to share the right information at the right time. We work out of Austin and London.",
      "Communications",
      "Our Community Ops team is an international group of passionate and dedicated individuals, committed to enhancing the user experience across all channels for both Bumble and {{page_badoo_name}}. You’ll find us in London and Austin.",
      "Community Operations",
      "At Bumble, we have some of the greatest minds in the tech industry working for us. The People & Culture team ensures our continued growth is supported by maintaining our high standards. Being part of this team means you are a champion of Bumble’s culture and values, partnering with our department leads to help them build and retain their teams. Our team expands across London, Moscow, and Austin.",
      "People and Culture",
      "Our {{page_badoo_name}} and Bumble creative team members are based in London and Austin but have a global impact. With designers, copywriters, and content creators working cross-functionally with the entire organisation, these are the people who bring our mission to life.",
      "Creative & Design",
      "Every day, the products that we build touch millions of customers globally. With more than 300 engineers, our teams are obsessed with solving challenging and interesting problems.",
      "Engineering",
      "ENGINEERING",
      "The COE works as a cross-functional team, bringing the right minds together from around the globe to accelerate innovation and equip our teams with the necessary tools to bake diversity, equity, and inclusion (DEI) into all parts of the business. As internal resources and critical thought partners to every employee, this global team helps democratise DEI work and consistently empowers Bumble to build spaces that celebrate and champion the diverse voices that make us who we are.",
      "Centre of Excellence",
      "The Finance Team is based in London and Austin and oversees our investments in headcount, operating expenses, and capital investment. We use our unique view of numbers to see trends and opportunities that are developing within the company as well as across the industry.",
      "Finance",
      "Millions of people use our apps in over 150 countries around the world. To better understand them and create the best experience possible, we use data. It informs how we design and build our products, how we communicate with our community, and helps the business make insightful decisions. Our data scientists and engineers are based between London and Austin and work closely with all global teams.",
      "Business Intelligence",
      "Learn More",
      "The in-house legal team at Bumble gives proactive and creative legal guidance, responding to the day to day corporate and commercial needs of the company and helping the business achieve its vision. We are a team of brilliantly sharp minds who are not afraid to be challenged or think outside the box. We are based in London and Austin.",
      "Legal",
      "Our marketing and brand teams work hard to bring both the Bumble and {{page_badoo_name}} brand missions to life all over the world. They are a group of smart, talented and data driven professionals committed to creating a world where all relationships are healthy and equitable.",
      "Marketing",
      "MARKETING",
      "CAREERS AT BUMBLE",
      "Our <span class=\"is-italic has-underline\">Teams</span>",
      "The Product team is central to our company’s vision and success. They are entrepreneurs who innovate and execute ideas company-wide, collaborating with world-class engineers to create the best experience for our millions of users. You’ll find these team members in both London and Barcelona. You’ll also find the Localisation and Revenue teams as part of the Product family in London too!",
      "Product",
      "Customer understanding is at the heart of our decision making. By bringing people and markets to life, the research team aims to give focus and insight to our product, diminishing risk in the process. Our customer-centric thinking bleeds into everything else we do, including marketing, copy, and design. You’ll find our fast-growing and agile team in the London office.",
      "Research",
      "Hello, and welcome to the {{page_badoo_name}} Creative Studio! We spread our magic throughout the {{page_badoo_name}} business to create work that can flex seamlessly across the globe and into the app. Above all, our goal is to build a purposeful brand that people truly love.",
      "<span class=\"is-branded\">{{page_badoo_name}}</span> Creative",
      "The Bumble creative team consist of graphic designers, copywriters, video editors, and motion graphics designers all dedicated to bringing Bumble’s values to life. From what you see within the app to what you see out in the world, our creatives work hard to inspire and empower our community every day.",
      "<span class=\"is-branded\">Bumble</span> Creative",
      "We’re a small team with a big impact on the online dating and connection world. We create simple, delightful and emotionally-driven products using the holistic approach that product and design should work hand in hand with marketing and branding, and one should inform the other. You’ll find us in London and Barcelona.",
      "Product Design",
      "CREATIVE & DESIGN",
      "Human connection is, and will always be, at the heart of everything we do. Our people are a fundamental pillar and this is demonstrated in what we have created over the last decade and what we’ll continue to build in the future.",
      "A Force for Good",
      "MEET OUR TEAMS",
      "<p>Our culture of innovation and our strong ability to execute allows us to build products that can truly change the trajectory of someone’s life by allowing them to find the connection they are looking for.</p> <p>This translates to a very powerful, tech-enriching, and unique feeling we all share and are very proud of as Bumble engineers. You’ll find us working in London, Moscow and Barcelona.</p>",
      "Our tech is paramount and will keep serving us as we move forward. We are diversifying our tech stacks, transitioning to a more polyglot software engineering house that takes a best-in-class approach when it comes to provisioning solutions for the product domain we live in.",
      "Technology First",
      "Visit the Bumble Tech Blog",
      "We’re heading into our next chapter with cross-functional delivery. Autonomous product-engineering units that deliver independently and provide their own roadmaps, while maintaining consistency on tech, quality and other non-functional aspects.",
      "Due to the sheer size of our platforms and customer base, the technical challenges we face are fascinating to deal with and require a great degree of passion, curiosity, teamwork and balance.",
      "<span class=\"is-branded\">How We Work</span>",
      "<p>At Bumble, we’re always working to create a culture rooted in <span class=\"is-branded\"><span class=\"is-italic\">kindness, honesty, inclusivity, accountability,</span></span> and <span class=\"is-branded\"><span class=\"is-italic\">growth.</span></span></p> <p>Every one of our team members, from the top down, helps support our efforts to foster an <span class=\"is-branded\"><span class=\"is-italic\">inclusive</span></span> workplace. Our dedication to diversity in all its forms has helped propel Bumble’s vision forward.</p> <p>Our priority is building a company that represents the communities we serve—and ensuring each employee is able to bring their whole self to work, knowing their skills and perspectives are valued and integral to our mission.</p>",
      "<span class=\"is-branded\"><span class=\"is-italic\">A MESSAGE</span></span> FROM OUR FOUNDER AND CEO",
      "Accept all",
      "Manage options",
      "We use cookies to make our site work better. This includes analytics cookies and advertising cookies. For more information, please check our <a p0>Cookie Policy</a>.",
      "Let us know you agree to cookies",
      "IF YOU BELIEVE IN <span class=\"is-italic\"><span class=\"has-circle\">OUR MISSION</span></span> AND ARE <span class=\"has-underline\"><span class=\"is-italic\">COLLABORATIVE</span></span> AND <span class=\"has-underline\"><span class=\"is-italic\">MOTIVATED</span></span> - WE’RE LOOKING FOR YOU!",
      "Cookie Policy",
      "Gender Pay Gap",
      "Privacy Policy",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "<span class=\"is-italic\"><span class=\"has-circle\">OUR MISSION</span></span> IS TO CREATE A WORLD WHERE ALL <span class=\"has-underline\"><span class=\"is-italic\">RELATIONSHIPS</span></span> ARE <span class=\"has-underline\"><span class=\"is-italic\">HEALTHY</span></span> AND EQUITABLE",
      "About",
      "Benefits",
      "Life",
      "Teams",
      "Menu",
      "Join us now",
      "Open roles",
      "{{name1}} & {{name2}}",
      "Met {{year}}",
      "A <span class=\"is-italic\"><span class=\"has-circle\">MESSAGE</span></span> FROM <span class=\"has-underline\"><span class=\"is-italic\">OUR</span></span> CEO",
      "LIFE AT BUMBLE",
      "We strongly encourage people of colour, lesbian, gay, bisexual, transgender, queer and non-binary people, veterans, and individuals with disabilities to apply. Bumble is an equal opportunity employer and welcomes everyone to our team. If you need reasonable accommodation at any point in the application or interview process, please let us know.",
      "In your application, please feel free to note which pronouns you use (For example: she/her, he/him, they/them, etc).",
      "<span class=\"has-circle\"><span class=\"is-italic\">EVERYONE</span></span> HAS A SEAT AT THE <span class=\"is-italic\"><span class=\"has-underline\">TABLE.</span></span>",
      "We empower our employees to overcome challenges and achieve great things by supporting them in both their personal and professional journeys. You may join a small team but you’ll have a global impact and all the opportunity to grow.",
      "<span class=\"is-branded\">Make moves</span> for your career.",
      "Your work will have a real human impact and can change the course of people’s lives for the better. Join our team, and you’ll feel the joy we feel every time we read about yet another successful connection.",
      "<span class=\"is-branded\">Make moves,</span> change lives.",
      "You’ll join a team committed to creating a world where all relationships are healthy and equitable. We believe the internet can become a kinder, more respectful place for people to connect.",
      "<span class=\"is-branded\">Make moves</span> for good.",
      "WHY BUMBLE",
      "<span class=\"is-italic\"><span class=\"has-circle\">MAKE</span></span> MOVES <br />THAT <span class=\"is-italic\"><span class=\"has-underline\">MATTER</span></span>",
      "What {{page_badoo_name}} employees are saying",
      "What {{page_badoo_name}} employees are saying",
      "<span class=\"is-branded\">Create</span> Honestly",
      "The magic of Bumble has always been the people. We are a creative and results-orientated team who are passionate about forwarding Bumble’s mission to empower women through impactful marketing and communications.",
      "Director, EMEA Marketing, London",
      "Our work culture is inclusive, collaborative, transparent, respectful, inspiring, interesting and fun! I feel truly valued and happy at Bumble. The place has good vibes.",
      "iOS Developer, London",
      "We’re building products for millions of people and it’s an amazing feeling knowing you really can have an impact and influence peoples’ lives.",
      "Head of Product {{page_badoo_name}}, London",
      "What Bumble employees are saying",
      "<span class=\"is-branded\">Making moves</span> from home",
      "You take ownership and responsibility for your work and always stay committed to the safety of our community.",
      "Accountability",
      "You take initiative, you’re proactive, and intentional.",
      "Make the First Move™",
      "You embrace challenges, take risks, and use mistakes as an opportunity to learn and grow.",
      "Growth",
      "OUR VALUES",
      "You always do what’s best for the Bumble community, even when it is uncomfortable, and give constructive feedback to achieve the best possible results.",
      "Honesty",
      "You seek out and embrace differing perspectives, you collaborate globally, and you lift others up in your pursuit of Bumble’s mission.",
      "Inclusivity",
      "You treat everyone in the Bumble community with kindness and respect and abide by our golden rule: Treat everyone as they want to be treated.",
      "Kindness",
      "Everyone gets 24 days of paid time off annually plus public holidays.",
      "24 Days Annual Leave",
      "Get free access to online GP appointments through the Babylon app.",
      "Babylon Health App",
      "Product & Engineering teams can attend 2 conferences each year, all expenses paid.",
      "Conference Allowance",
      "Save on the cost of a brand new bike and all the gear with Cycle to Work.",
      "Cycle to Work",
      "Dental coverage is also provided for you and your family.",
      "Dental Insurance",
      "We’ll double match your pension contribution up to 6% of your annual salary.",
      "Double Matched Pension up to 6%",
      "Eligible to participate in our Long-Term Incentive Plan to share in the success of our company.",
      "Bumble Equity Incentive Plan",
      "When working in the office, enjoy free catered meals, a fully stocked kitchen, and unlimited snacks. While working from home, we’ll send you meal delivery or a food voucher every first Friday of the month.",
      "We’ll pay up to £40 towards your monthly gym membership.",
      "GymFlex",
      "If you’re ever unable to work for a long period of time due to illness or injury, you’ll be covered.",
      "Income Protection",
      "In addition to primary healthcare, we’ll provide you more mental health resources to keep your mind at ease.",
      "Mental Health Support",
      "LONDON <span class=\"is-italic\"><span class=\"has-circle\">BENEFITS</span></span>",
      "Enhanced paid maternity and paternity leave for new parents, giving you ample time to adjust to your new reality and bond with your child.",
      "Enhanced Parental Leave",
      "Easily donate money to a registered UK charity every month directly through payroll.",
      "Payroll Giving",
      "This covers the cost of private medical treatment and in some cases can help you get seen more quickly.",
      "Get the benefits of an annual season ticket - but pay for it in monthly instalments.",
      "Season Ticket Loan",
      "We offer a number of team sports and activities which all employees are eligible to join.",
      "Sports Leagues",
      "You’ll get to participate in fun, virtual team-building activities within your department.",
      "Regular Team Builds",
      "We’ll cover the cost of an eyesight test and provide discounts for eyewear.",
      "Vision Vouchers",
      "The office has a private room where you can meditate, pray, breastfeed, or just take a few minutes for yourself.",
      "Wellbeing Room",
      "The {{page_badoo_name}} marketing and communications team works across all channels to help create brand affinity and spread our message of honest dating all over the world. We devise through the line strategies based on insights and data to deliver global and localised campaigns, ensuring our product is always at the heart. From our home base in London, we’re always looking for innovative and exciting ways to bring the brand to life.",
      "<span class=\"is-branded\">{{page_badoo_name}}</span> Marketing",
      "Both the Bumble marketing team and brand team include strategists and creatives who work closely together across 6 different countries — U.S., U.K., Australia, India, Germany, and Mexico. The marketing team focuses on ways to bring the brand to life across major mission-centric media campaigns, community marketing events, in-app partnerships, and more.",
      "The brand team creates cultural conversations important to the business by building relationships with influencers, producing original video series, designing award-winning social media, and more.",
      "<span class=\"is-branded\">Bumble</span> Marketing",
      "You can opt in for additional health coverage should you ever be involved in an accident (inside or outside of work).",
      "Accident Insurance",
      "No matter what your seniority level is, you’ll be guaranteed 31 days of paid time off annually.",
      "31 Days Annual Leave",
      "You’ll receive an annual budget to spend on a range of benefits including health insurance, education and gym memberships.",
      "Flexible Benefits",
      "Pick the health coverage that best meets you and your family’s needs.",
      "Health Insurance",
      "When working in the office, a general practitioner is available each week.",
      "In-Office Doctor",
      "MOSCOW <span class=\"is-italic\"><span class=\"has-circle\">BENEFITS</span></span>",
      "We also provide coverage for the cost of any needed medications.",
      "Pharmacy Program",
      "Get competitive with your colleagues or practice with a trainer twice a week.",
      "Table Tennis at the Office",
      "Apply Now",
      "LOCATIONS",
      "OPEN <span class=\"is-italic\"><span class=\"has-underline\">ROLES</span></span>",
      "Share this opportunity",
      "SORT BY",
      "TEAMS",
      "Bumble Careers",
      "Making Moves",
      "You’ll join a team committed to creating a world where all relationships are healthy and equitable",
      "Bumble is hiring",
      "We are using cookies. If you do not adjust your settings we assume you are ok with this.",
      "Download the App",
      "Download for Android",
      "Download for iOS",
      "We’re sorry, but we can’t find the page you were looking for.",
      "Sign In",
      "Join",
      "Join the Hive!",
      "Oops, something went wrong"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "days"
            ],
            "singular": [
                  "day"
            ]
      },
      "hour": {
            "plural": [
                  "hours"
            ],
            "singular": [
                  "hour"
            ]
      },
      "minute": {
            "plural": [
                  "minutes"
            ],
            "singular": [
                  "minute"
            ]
      },
      "month": {
            "plural": [
                  "months"
            ],
            "singular": [
                  "month"
            ]
      },
      "second": {
            "plural": [
                  "seconds"
            ],
            "singular": [
                  "second"
            ]
      },
      "year": {
            "plural": [
                  "years"
            ],
            "singular": [
                  "year"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
